import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        cartLoading: false,
        cartSize: "...",
        cartProductList: {},
        cartOpen: false,
    },
    mutations: {
        setCartSize(state, cartSize) {
            state.cartSize = cartSize
        },
        setCartLoading(state, cartLoading) {
            state.cartLoading = cartLoading
        },
        setCartProductList(state, productList) {
            state.cartProductList = productList
        },
        setCartOpen(state, cartOpen) {
            state.cartOpen = cartOpen
        },
        removeProductFromCart(state, productId) {
            const found = state.cartProductList.relationships.order_items_groups.findIndex(o => o.id === productId)

            if (found > -1) {
                state.cartProductList.relationships.order_items_groups.splice(found, 1)
                state.cartSize = `${parseInt(state.cartSize) - 1}`
            }
        },
    }
})