import {mapMutations, mapState} from "vuex";
import cartStore from "@/components/mixins/cartStore";
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
    store: cartStore,
    data: () => ({
        addToCartLoading: false,
        buyNowLoading: false,
        order: null,
        storage: 'default'
    }),
    computed: {
        ...mapState(['cartLoading', 'cartSize', 'cartProductList', 'cartOpen']),
        products() {
            return this.cartProductList?.relationships?.order_items_groups || null
        },
        products_count() {
            return this.products ? this.products.length : 0
        },
        delivery_options() {
            let inmediatoPrice = 0

            // TODO: una vez que se tenga modulo por ubicacion cambiar este valor
            inmediatoPrice = 300

            // TODO: revisar si se estara aplicando el 10% de cada prenda para el pago de apartado
            // let total = 0
            // for (const product of this.products) {
            //     total += product.sku.prices.find(p => p.price_list_id === 3).price
            // }
            //
            // let paymentFee = total * 0.1
            // if (paymentFee < 250) {
            //     paymentFee = 250
            // }

            let paymentFee = 300 * this.products_count

            return [
                {type: 'inmediato', price: inmediatoPrice, paymentFee: 0},
                {type: 'normal', price: 0, paymentFee: paymentFee},
            ]
        },
        products_subtotal() {
            let subtotal = 0
            for (const product of this.products) {
                subtotal += product.sku.prices.find(p => p.price_list_id === 3).price
            }
            return subtotal
        },
        products_discount_total() {
            let discountTotal = 0
            for (const product of this.products) {
                discountTotal += (product.sku.prices.find(p => p.price_list_id === 3).price - product.on_sale_price)
            }
            return discountTotal
        },
    },
    methods: {
        ...mapMutations(['setCartSize', 'setCartLoading', 'setCartProductList', 'removeProductFromCart', 'setCartOpen']),
        updateInfo(openBar = false) {
            this.setCartLoading(true)
            this.$axios.get('/cart')
                .then((response) => {
                    let response_data = response.data.data
                    let item_groups = response_data?.relationships?.order_items_groups
                    this.setCartProductList(response_data)
                    if (openBar) this.setCartOpen(true)
                    this.setCartSize((item_groups || []).length)
                })
                .catch((error) => {
                    console.warn(error)
                })
                .finally(() => {
                    this.setCartLoading(false)
                })
        },
        addToCart(slug, redirect = false) {
            if (!this.addToCartLoading && !this.buyNowLoading) {
                this.addToCartLoading = !redirect
                this.buyNowLoading = redirect
                this.$axios.post('/cart', {data: {slug}})
                    .then(r => {
                        if (r.data.error) {
                            this.$swal('Error...', r.data.error, 'error')
                        } else {
                            this.updateInfo(true)
                        }
                        if (redirect) window.location.href = '/generar-pedido'
                    })
                    .finally(() => {
                        this.addToCartLoading = false
                        this.buyNowLoading = false
                    })
            }
        },
        removeItemFromList(productId) {
            this.removeProductFromCart(productId)
        },
    }
}